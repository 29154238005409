import axiosClient from '../axiosClient';

export const apiTiktoken = async (value) => {
    if (value) {
        try {
            const res = await axiosClient.post('count-token', { data_str: value ? value : '' });
            if (res.status === 200 || res.status === 201) {
                return res.data.token;
            } else {
                return 0;
            }
        } catch (err) {
            return 0;
        }
    } else {
        return 0;
    }
};

export const getVoiceAnswer = async (conversation_id) => {
    if (!conversation_id) {
        return {
            success: false,
            message: 'Conversation ID is required',
            data: null,
        };
    }

    try {
        const res = await axiosClient.get(`tts-answer/${conversation_id}`, {
            responseType: 'blob', // Chỉ định loại dữ liệu phản hồi là 'blob'
        });

        if (res.status === 200 || res.status === 201) {
            return {
                success: true,
                message: 'Voice answer retrieved successfully',
                data: res.data,
            };
        } else {
            return {
                success: false,
                message: 'Failed to retrieve voice answer',
                data: null,
            };
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return {
                success: false,
                message: 'Error retrieving voice answer',
                data: null,
                error: {
                    status: error.response.status,
                    message: error.response.data.message || 'Unknown error',
                },
            };
        } else if (error.request) {
            // The request was made but no response was received
            return {
                success: false,
                message: 'No response received from the server',
                data: null,
                error: {
                    message: 'No response received',
                },
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            return {
                success: false,
                message: 'Error setting up the request',
                data: null,
                error: {
                    message: error.message,
                },
            };
        }
    }
};

export const getInforBot = async (id) => {
    try {
        const res = await axiosClient.get(`bots/${id}/`);

        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getUerInfor = async () => {
    try {
        const res = await axiosClient.get(`get-info-user`);

        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getFunctionCalling = async (bot_id) => {
    try {
        const res = await axiosClient.get(`/function-calling-mindmaid/?bot_id=${bot_id}`);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};
export const getProfileAssistant = async (bot_id) => {
    try {
        const res = await axiosClient.get(`/get-assistant-profile?assistant=${bot_id}`);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getlistLmm = async () => {
    try {
        const res = await axiosClient.get('llms');
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getListBotgpts = async (query) => {
    try {
        const res = await axiosClient.get(`/assistant/${query}`);
        if (res.status === 200 || res.status === 201) {
            let newData = res.data.results.map((item) => ({
                label: item.name,
                key: item.id,
                avatar: item.preview_logo,
                type: 'gpts',
            }));
            return newData;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getVatartGpts = async (data) => {
    const avatarPromises = data.map(async (i) => {
        const profile = await getProfileAssistant(i.key);
        return profile;
    });
    const avatars = await Promise.all(avatarPromises);
    const updatedData = data.map((item) => {
        const matchedAvatar = avatars.find((j) => j.assistant === item.key);
        if (matchedAvatar) {
            return {
                ...item,
                avatar: matchedAvatar.preview_logo,
            };
        }
        return item;
    });

    return updatedData;
};

export const getListBotMM = async (query) => {
    try {
        const res = await axiosClient.get(`/bots/${query}`);
        if (res.status === 200 || res.status === 201) {
            let newData = res.data.results.map((item) => ({
                label: item.name,
                key: item.id,
                avatar: item.preview_logo,
                utterances:item?.utterances,
                type: 'mindmaid',
            }));
            return newData;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateConnectFunctionCalling = async (data, isEnable) => {
    const payload = {
        bot: data?.bot,
        function_tools: data.function_tools,
        function_name: data.function_name,
        isEnable: isEnable,
        run_tools: data.run_tools,
    };
    try {
        const res = await axiosClient.patch(`/function-calling-mindmaid/${data?.id}/`, payload);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const createFormPersonalization = async (bot_id) => {
    const payload = {
        bot: bot_id,
        detail_preferences: [],
        phone_number_title: 'Số điện thoại',
        fullname_title: 'Họ và tên',
    };
    try {
        const res = await axiosClient.post('v3/user-preference', payload);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};
export const autoUpdateNewNameGpts = async (data) => {
    const payload = new FormData();
    payload.append('boxChat_title', data.name);

    try {
        await axiosClient.patch(`/assistant-profile/${data?.idProfle}/`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {}
};

export const autoUpdateNewName = async (data) => {
    const payload = new FormData();
    payload.append('boxChat_title', data.name);

    try {
        await axiosClient.patch(`bots/${data.id}/`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {}
};

export const createVectorStore = async (data) => {
    const payload = {
        openai_api_key: data.openai_api_key,
        file_ids: data.file_ids || [],
        name: data.name,
    };
    try {
        const res = await axiosClient.post('vector-store-assistant/', payload);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getStoreBot = async (query='') => {
    try {
        const res = await axiosClient.get(`/storebots/${query}`);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};
export const getStoreBotDetail = async (storeId) => {
    try {
        const res = await axiosClient.get(`/storebots/${storeId}/`);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateVectorStore = async (data) => {
    const payload = {
        openai_api_key: data.openai_api_key,
        file_ids: data.file_ids || [],
        name: data.name,
    };
    try {
        const res = await axiosClient.put(`vector-store-assistant/${data?.vector_store_ids[0]}`, payload);
        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};
